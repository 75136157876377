export const checkSubscriptionStatus = currentUser => {
  if (!currentUser?.attributes?.profile?.metadata) {
    return false;
  }

  const { metadata } = currentUser.attributes.profile;
  const subscriptionStatus = metadata.subscriptionStatus;
  const subscriptionEndDate = metadata.subscriptionEndDate;

  if (!subscriptionStatus || !subscriptionEndDate) {
    return false;
  }

  // Verify if the subscription is active and not expired
  return (
    subscriptionStatus === 'active' &&
    new Date(subscriptionEndDate) > new Date()
  );
};

export const getSubscriptionDetails = currentUser => {
  if (!currentUser?.attributes?.profile?.metadata) return null;
  
  const metadata = currentUser.attributes.profile.metadata;
  
  if (!metadata.subscriptionStatus) return null;
  
  // Calculate the actual status based on cancelAtPeriodEnd
  const status = metadata.subscriptionCancelAtPeriodEnd ? 'canceling' : metadata.subscriptionStatus;
  
  // console.log('Subscription details:', {
  //   rawStatus: metadata.subscriptionStatus,
  //   cancelAtPeriodEnd: metadata.subscriptionCancelAtPeriodEnd,
  //   calculatedStatus: status
  // });

  return {
    status,
    startDate: metadata.subscriptionStartDate,
    endDate: metadata.subscriptionEndDate,
    planId: metadata.subscriptionPlanId,
    stripeCustomerId: metadata.stripeCustomerId,
    subscriptionId: metadata.subscriptionId,
    cancelAtPeriodEnd: metadata.subscriptionCancelAtPeriodEnd
  };
}; 