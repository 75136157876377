import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import css from './SubscriptionSuccessPage.module.css';

const SubscriptionSuccessPage = ({ currentUser }) => {
  const history = useHistory();

  useEffect(() => {
    // If there is no authenticated user, redirect to the home page
    if (!currentUser?.id?.uuid) {
      history.push('/');
    }
  }, [currentUser, history]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    padding: '2rem',
    background: 'linear-gradient(135deg, #1345CA 0%, #0B0D17 100%)',
    color: '#FFFFFF',
    textAlign: 'center'
  };

  return (
    <div style={containerStyle}>
        <img 
          src={require('../../assets/michael-goofy.png')} 
          alt="charles being happy" 
          className={css.logo}
          style={{ maxWidth: '200px', maxHeight: '200px', width: 'auto', height: 'auto', borderRadius: '15px' }}
        />
      <h1 style={{ marginBottom: '0px' }}>Welcome to Stuvi Creator+</h1>
      <p>Your subscription has been activated successfully. Start booking with benefits on selected studios.</p>
      <button 
        onClick={() => history.push('/s?pub_featuredStudio=yes')}
        className={css.returnButton}
      >
       Browse featured studios
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

export default connect(mapStateToProps)(SubscriptionSuccessPage); 