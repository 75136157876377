import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './SubscriptionCard.module.css';
import { FormattedMessage } from 'react-intl';
import { getSubscriptionDetails } from '../../util/subscription';
import { stripeOperations } from '../../util/api';

const SubscriptionCard = ({ currentUser, intl }) => {
  const [transform, setTransform] = useState('');
  const history = useHistory();
  const subscription = getSubscriptionDetails(currentUser);
  const hasActiveSubscription = subscription?.status === 'active' || subscription?.status === 'canceling';

  const handleClick = async () => {
    const isAuthenticated = currentUser?.id?.uuid && 
                          currentUser?.attributes?.email && 
                          currentUser?.attributes?.emailVerified;
    
    if (!isAuthenticated) {
      history.push('/signup');
      return;
    }

    if (hasActiveSubscription) {
      // If the user has an active subscription or scheduled for cancelation, go to manage subscription
      history.push('/account/manage-subscription');
    } else {
      // If the user does not have a subscription, go to checkout
      try {
        const { url } = await stripeOperations.createCheckoutSession({
          userId: currentUser.id.uuid,
          userEmail: currentUser.attributes.email,
        });
        
        if (url) {
          window.location.href = url;
        } else {
          console.error('No se recibió URL de checkout');
        }
      } catch (error) {
        console.error('Error al crear sesión de checkout:', error);
      }
    }
  };

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const rotateX = (y - centerY) / 25;
    const rotateY = -(x - centerX) / 25;

    setTransform(`perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
  };

  const handleMouseLeave = () => {
    setTransform('perspective(1000px) rotateX(0deg) rotateY(0deg)');
  };

  return (
    <div 
      className={css.card}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{ transform }}
    >
      <div className={css.cardHeader}>
        <div className={css.pricing}>
          <span className={css.price}>$35</span>
          <span className={css.period}>/month</span>
        </div>
      </div>
      
      <div className={css.cardContent}>
        <div className={css.benefitsSection}>
          {/* <h3 className={css.sectionTitle}>Subscription Benefits:</h3> */}
          <ul className={css.benefitsList}>
            <li className={css.benefitItem}>
              <span className={css.icon}>🕐</span>
              <span>
                <span className={css.underlinedText}>
                  1 free hour per booking
                  <span className={css.tooltip2}>
                    Valid for the first 3 bookings of each month, when booking 2 or more hours
                  </span>
                </span>
              </span>
            </li>
            <li className={css.benefitItem}>
              <span className={css.icon}>👥</span>
              <span className={css.underlinedText}>
                Hyper-access to Stuvi's Community
                <span className={css.tooltip}>
                  • Discord Access
                  <br />
                  • Exclusive live events
                  <br />
                  • Stuvi's Playlists
                </span>
              </span>
            </li>
            <li className={css.benefitItem}>
              <span className={css.icon}>📍</span>
              <span className={css.underlinedText}>
                Support local studios
                <span className={css.tooltip2}>
                    By booking through Stuvi with Creator+, we can lower our commission to basically only cover the cost of the transaction, making local studios a lot more money. 
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      
      <div className={css.cardFooter}>
        <button 
          className={css.subscribeButton}
          onClick={handleClick}
        >
          <FormattedMessage 
            id={hasActiveSubscription 
              ? "SubscriptionCard.manageSubscription" 
              : "SubscriptionCard.subscribe"
            } 
          />
        </button>
      </div>
    </div>
  );
};

SubscriptionCard.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.shape({
      uuid: PropTypes.string
    }),
    attributes: PropTypes.shape({
      email: PropTypes.string,
      emailVerified: PropTypes.bool
    })
  })
};

export default SubscriptionCard; 